<template>
  <div class="app-container">
    <div class="section-title">账户信息</div>
    <div class="balance-container">
      <div class="item">
        <span class="label">姓名:</span>
        <span class="value">{{accountInfo.realNm}}</span>
      </div>
      <div class="item">
        <span class="label">身份证号:</span>
        <span class="value">{{accountInfo.idNo}}</span>
      </div>
      <div class="item">
        <span class="label">账号:</span>
        <span class="value">{{accountInfo.account}}</span>
      </div>
      <div class="item">
        <span class="label">开户行:</span>
        <span class="value">{{accountInfo.bankNm}}</span>
      </div>
      <div class="item">
        <span class="label">银行预留手机号:</span>
        <span class="value">{{accountInfo.bankMbl}}</span>
      </div>
    </div>

    <div class="section-title top-line">账户余额</div>
    <div class="balance-container">
      <div class="item">
        <span class="label">可用余额:</span>
        <span class="value">{{balance.canUse}}元</span>
      </div>
      <div class="item">
        <span class="label">实际余额:</span>
        <span class="value">{{balance.real}}元</span>
      </div>
      <div class="item">
        <span class="label">冻结余额:</span>
        <span class="value">{{balance.freeze}}元</span>
      </div>
      <div class="item">
        <span class="label">待结算余额:</span>
        <span class="value">{{balance.waitSettle}}元</span>
      </div>
    </div>
    <div class="balance-container">
      <div class="item">
        <span class="label">可用不可出金余额:</span>
        <span class="value">{{balance.canUseNotCash}}元</span>
      </div>
      <div class="item">
        <span class="label">不可用可出金余额:</span>
        <span class="value">{{balance.notUseNotCash}}元</span>
      </div>
    </div>

    <div class="section-title top-line">收支明细</div>
    <div class="filter-container">
      <!-- <el-input v-model="listQuery.keyword" placeholder="请输入订单号" style="width: 220px;" clearable/> -->
      <el-date-picker v-model="dateArr" type="datetimerange" align="right" value-format="yyyy-MM-dd HH:mm:ss" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']" :picker-options="pickerOptions">
      </el-date-picker>
      <el-button type="primary" icon="el-icon-search" @click="handleFilter">搜索</el-button>
    </div>
    <el-table v-loading="listLoading" :data="list" element-loading-text="Loading" fit highlight-current-row>
      <el-table-column label="订单号" min-width="100" align="center">
        <template slot-scope="scope">
          {{ scope.row.tradeOrdNo }}
        </template>
      </el-table-column>
      <el-table-column label="交易金额" min-width="100" align="center">
        <template slot-scope="scope">
          {{ scope.row.trAmt/100 }}
        </template>
      </el-table-column>
      <el-table-column label="交易日期" min-width="100" align="center">
        <template slot-scope="scope">
          {{ scope.row.tradt }}
        </template>
      </el-table-column>
      <el-table-column label="交易时间" min-width="100" align="center">
        <template slot-scope="scope">
          {{ scope.row.tratm }}
        </template>
      </el-table-column>
      <!-- <el-table-column label="交易完成后账户余额" min-width="100" align="center">
        <template slot-scope="scope">
          {{ scope.row.accBalance }}
        </template>
      </el-table-column> -->
      <el-table-column label="备注" min-width="100" align="center">
        <template slot-scope="scope">
          {{ scope.row.remk }}
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-container">
      <el-pagination :current-page="listQuery.page" :page-sizes="[5,10,20,30, 50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
import { mapGetters } from "vuex";
var moment = require("moment");

export default {
  data() {
    return {
      info: null,
      accountInfo: null,
      balance: null,
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        startTime: '',
        endTime: '',
      },
      dateArr: [moment().format("YYYY-MM-DD 00:00:00"),moment().format("YYYY-MM-DD 23:59:59")],
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
    };
  },
  created() {
    this.getInfo();
    this.getAccountInfo();
    this.getAccountBalance();
    this.getList();
  },
  mounted() {},
  computed: {
  },
  watch: {
  },
  methods: {
    getInfo() {
      this.listLoading = true;
      request({
        url: "/api/backend/ccb/userInfo",
        method: "get",
        params: this.listQuery
      }).then(response => {
        this.info = response.data;
      });
    },
    getAccountInfo() {
      this.listLoading = true;
      request({
        url: "/api/backend/ccb/accountInfo",
        method: "get",
        params: this.listQuery
      }).then(response => {
        this.accountInfo = response.data;
      });
    },
    getAccountBalance() {
      request({
        url: "/api/backend/ccb/accountBalance",
        method: "get",
        params: this.listQuery
      }).then(response => {
        this.balance = response.data;
      });
    },
    getList() {
      if (!this.dateArr) {
        this.$message({
          type: "warning",
          message: "请选择筛选时间"
        });
        return;
      }
      this.listQuery.startTime = this.dateArr[0];
      this.listQuery.endTime = this.dateArr[1];
      this.listLoading = true;
      request({
        url: "/api/backend/ccb/accountBill",
        method: "get",
        params: this.listQuery
      }).then(response => {
        this.list = response.data.LISTS;
        this.total = parseInt(response.data.totCnt);
        this.listLoading = false;
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 6px;
}
.floor-container {
  display: inline-block;
  width: 160px;
  height: 160px;
  margin-right: 25px;
  margin-bottom: 25px;
}
.balance-container {
  display: flex;
  margin: 4px auto;
  .item {
    margin-right: 20px;
  }
  .label {
    color: #414141;
  }
  .value {
    font-weight: bold;
    color: red;
  }
}
.section-title {
  font-weight: bold;
  padding-top: 15px;
  padding-bottom: 15px;
}
.top-line {
  margin-top: 20px;
  border-top: 1px solid #f0f0f0;
}
</style>
